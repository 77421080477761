import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CHECKLIST_RESPOSTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CHECKLIST_RESPOSTA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('checklistRespostaForm', registro)
    ];
}

export function getLista() {
	return async (dispatch, getState) => {

        await axios.get(`${consts.API_URL}/checklist`)
		.then(async retorno => {
			dispatch({
				type: 'CHECKLIST_RESPOSTA_LISTADO',
				payload: retorno
			});
		})
		.catch(erro => {
			setErroAPI(erro);
		});
    };
}

export function getListaRealizado() {
	return async (dispatch, getState) => {

        await axios.get(`${consts.API_URL}/checklistResposta`)
		.then(async retorno => {
			dispatch({
				type: 'CHECKLIST_RESPOSTA_REALIZADO_LISTADO',
				payload: { data: retorno.data.filter(item => item.id_usuario_inclusao == getState().auth.usuario.id) }
			});
		})
		.catch(erro => {
			setErroAPI(erro);
		});
    };
}

export function getListaArea(idChecklist) {
	return async (dispatch, getState) => {

        await axios.get(`${consts.API_URL}/checklistArea?id_checklist=${idChecklist}`)
		.then(async retorno => {
			dispatch({
				type: 'CHECKLIST_RESPOSTA_AREA_LISTADO',
				payload: retorno
			});

		})
		.catch(erro => {
			setErroAPI(erro);
		});
    };
}

export function getListaPergunta(idChecklist) {
	return async (dispatch, getState) => {

        await axios.get(`${consts.API_URL}/checklistItem?id_checklist=${idChecklist}`)
		.then(async retorno => {
			dispatch({
				type: 'CHECKLIST_RESPOSTA_PERGUNTA_LISTADO',
				payload: retorno
			});

		})
		.catch(erro => {
			setErroAPI(erro);
		});
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/grupoCliente/listarSelect`);
    return {
        type: 'CHECKLIST_RESPOSTA_GRUPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'CHECKLIST_RESPOSTA_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario`);
    return {
        type: 'CHECKLIST_RESPOSTA_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function iniciarChecklist(checklist, evento) {
	return async (dispatch, getState) => {

		let registro = {
			id_base: `${getState().auth.usuario.id}_${new Date().getTime()}`,
			id_checklist: checklist.id,
			id_status: checklist.id_status,
			id_cliente: checklist.id_cliente,
			id_usuario: checklist.id_usuario,
			agendamento: checklist.agendamento,
			data_agendamento: checklist.data_agendamento,
			ativo: true,
			id_usuario_inclusao: getState().auth.usuario.id,
			id_usuario_alteracao: getState().auth.usuario.id
		};

		await axios.post(`${consts.API_URL}/checklistResposta`, registro)
		.then(async retorno => {
			registro = {
				...registro,
				id: retorno.data.id
			};
		})
		.catch(erro => {
			setErroAPI(erro);
		});

		if (registro.id) {

			let listaArea = [];

			await axios.get(`${consts.API_URL}/checklistArea?id_checklist=${registro.id_checklist}`)
			.then(async retorno => {
				listaArea = retorno.data;	
			}).catch(erro => {
				setErroAPI(erro);
			});

			let listaPergunta = [];

			await axios.get(`${consts.API_URL}/checklistItem?id_checklist=${registro.id_checklist}`)
			.then(async retorno => {
				listaPergunta = retorno.data;
			}).catch(erro => {
				setErroAPI(erro);
			});

			for (let i = 0; i < listaArea.length; i++) {
				const area = listaArea[i];

				for (let j = 0; j < listaPergunta.filter(pergunta => pergunta.id_area == area.id).length; j++) {
					const pergunta = listaPergunta.filter(pergunta => pergunta.id_area == area.id)[j];

					let item = {
						id_base: `${getState().auth.usuario.id}_${i}_${j}_${new Date().getTime()}`,
						id_checklist_resposta: registro.id,
						id_item:pergunta.id,
						descricao_item: pergunta.descricao,
						id_tipo: pergunta.id_tipo,
						opcoes: pergunta.opcoes,
						id_equipamento: pergunta.id_equipamento,
						resposta: null,
						complemento_comentario: null,
						complemento_anexo: null,
						complemento_foto: null,
						complemento_assinatura: null,
						ordem: pergunta.ordem,
						ativo: true,
						id_usuario_inclusao: getState().auth.usuario.id,
						id_usuario_alteracao: getState().auth.usuario.id
					};
					
					await axios.post(`${consts.API_URL}/checklistRespostaItem`, item)
					.then(async retorno => {
						
						let respostas = (registro.itens || []).map(resposta => ({ ...resposta }));
						respostas.push({
							...item,
							id: retorno.data.id,
						});
						
						registro = {
							...registro,
							itens: respostas
						};
					})
					.catch(erro => {
						setErroAPI(erro);
						console.log(erro);
					});

				}
				
			}
			
			evento(registro);
			dispatch(getListaRealizado());
		}
    };
}

export function salvarCheklistStatus(registro) {
	return async (dispatch, getState) => {

		await axios.put(`${consts.API_URL}/checklistResposta`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id
		})
		.then(async retorno => {
			dispatch(getListaRealizado());
		})
		.catch(erro => {
			setErroAPI(erro);
		});	
    };
}

export function continuarChecklist(checklistResposta, evento) {
	return async (dispatch, getState) => {

		let registro = {
			...checklistResposta
		};

		await axios.put(`${consts.API_URL}/checklistResposta`, registro)
		.then(async retorno => {
			dispatch(getListaRealizado());
		})
		.catch(erro => {
			setErroAPI(erro);
		});

		await axios.get(`${consts.API_URL}/checklistRespostaItem?id_checklist_resposta=${registro.id}`)
		.then(async retorno => {
			registro = {
				...registro,
				itens: retorno.data
			};
		}).catch(erro => {
			setErroAPI(erro);
		});

		evento(registro);			
		
    };
}

export function salvarCheklistResposta(registro) {
	return async (dispatch, getState) => {

		await axios.put(`${consts.API_URL}/checklistRespostaItem`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(async retorno => {			
			
		}).catch(erro => {
			setErroAPI(erro);
		});
    };
}





