import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTela, initForm, iniciarChecklist
} from './checklistRespostaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class ChecklistRespostaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.endereco || this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='id_checklist'
								component={Select}
								options={this.props.listaChecklist.map(item => ({ id: item.id, valor: item.nome }))}
								label='Checklist'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />

							<Field
								name='id_grupo'
								component={Select}
								options={this.props.listaGrupo}
								label='Região / Grupo'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />

							{this.props.formularioValues.id_grupo &&
							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente.filter(item => item.id_grupo == this.props.formularioValues.id_grupo)}
								label='Cliente'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />}
							
							{this.props.agendamento &&
							<Field
								name='id_usuario'
								component={Select}
								options={this.props.listaUsuario.map(item => ({ id: item.id, valor: item.nome }))}
								label='Aplicador'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />}

							{this.props.agendamento &&
							<Field
								name='data_agendamento'
								component={LabelAndInputMask}
								label='Data de Agendamento' placeholder='Informe a data'
								cols='12 4 4 4'
								readOnly={readOnly}
								mask='99/99/9999' />}
															
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text={this.props.agendamento ? 'Agendar' : 'Iniciar'}
									type={'success'}
									icon={'fa fa-check'}
									event={() => {
										this.props.iniciarChecklist(
											this.props.formularioValues ? { 
												id: this.props.formularioValues.id_checklist, 
												id_cliente: this.props.formularioValues.id_cliente,
												id_status: this.props.agendamento ? 1 : 2,
												agendamento: this.props.agendamento ? true : false,
												id_usuario: this.props.agendamento ? this.props.formularioValues.id_usuario : this.props.sessao.id,
												data_agendamento: DateFormat.formatarDataTelaParaSql(this.props.formularioValues.data_agendamento)
											} : {}, 
											(checklistResposta) =>  {
												this.props.setModoTela('lista');
											}
										);
									}} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

ChecklistRespostaForm = reduxForm({form: 'checklistRespostaForm', destroyOnUnmount: false})(ChecklistRespostaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('checklistRespostaForm')(state),
	registro: state.checklistResposta.registro,
	listaChecklist: state.checklistResposta.lista,
	listaGrupo: state.checklistResposta.listaGrupo,
	listaCliente: state.checklistResposta.listaCliente,
	listaUsuario: state.checklistResposta.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, iniciarChecklist
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistRespostaForm);
